.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/* Adicione isso ao seu arquivo CSS ou use uma tag <style> no componente */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  max-width: 100%;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Estilo para a tabela */
.table-responsive {
  overflow-x: auto; /* Habilita a rolagem horizontal em telas menores */
}

/* Estilo para a paginação */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination a:hover {
  background-color: #0056b3;
}

.pagination .active a {
  background-color: lightgray;
  font-weight: bold;
}

.pagination .disabled a {
  background-color: #ddd;
  color: #888;
  cursor: not-allowed;
}

.pagination .previous,
.pagination .next {
  background-color: #6c757d;
}

.pagination .previous:hover,
.pagination .next:hover {
  background-color: #5a6268;
}

/* Responsividade para telas pequenas */
@media (max-width: 768px) {
  .pagination a {
    padding: 5px 10px;
    font-size: 12px;
  }

  .pagination {
    flex-wrap: wrap; /* Permite que os botões quebrem linha em telas menores */
  }

  .table th,
  .table td {
    padding: 8px 4px; /* Menor espaço para células em telas pequenas */
    font-size: 12px; /* Ajuste da fonte */
  }

  .table-responsive {
    -webkit-overflow-scrolling: touch; /* Melhora a rolagem em dispositivos iOS */
  }
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
